import decode from 'jwt-decode';
import { setToken, getToken, clearToken } from '../localstorage/auth';

export const onSignIn = (jwt) => {
  setToken(jwt);
};

export const getTokenUser = () => {
  let token = getJWTToken();
  let decoded = token && decode(token);
  if (decoded && decoded.user) {
    let u = decoded.user;
    let user = u; /*new User(
      u._id,
      u.firstName,
      u.lastName,
      u.email,
      u.username,
      u.fullName,
      u.customergroup,
      u.assigner,
      u.fixedApproval,
      u.fixedApprovers,
      u.language,
      u.invoiceApproval,
      u.superUser,
      u.emailcc,
      u.emailccAddresses,
      u.linkedUsers,
      u.showUsers,
      u.enableTrucks,
      u.changesAllowed,
      u.manualAssigner
    );*/
    return user;
  }
  return null;
};

export const onSignOut = () => {
  clearToken();
};

export const getJWTToken = () => {
  return getToken();
};

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(token.exp);
  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}

export const isSignedIn = () => {
  let token = getJWTToken();
  return !!token && !isTokenExpired(token);
};

export const requireAuth = (nextState, replace) => {
  if (!isSignedIn()) {
    replace({ pathname: '/signin' });
  }
};
