import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reducers from './redux/reducers';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import MuiTheme from './core/common/MuiTheme';
import App from './components/App';
import register, { unregister } from './registerServiceWorker';
import Unsupported from '././components/Unsupported';
import { isLegacy } from './core/common/LegacyFixer';

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : (r) => r;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

const theme = createMuiTheme(MuiTheme);

const nodo = () => {};

if (process.env.NODE_ENV !== 'development') {
  console.log = nodo;
  console.warn = nodo;
  console.error = nodo;
}

const app = isLegacy() ? (
  <Unsupported />
) : (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </MuiThemeProvider>
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>{app}</BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

if ('serviceWorker' in navigator) {
  unregister();
}
